.manager {
    .hero {
        background-image: url("/images/2024-09/hero-back-manager.jpg");
        color: white;
    }

    .focus-bg {
        @extend .bg-brand-manager-pale;
    }

    .accordion-button {
        &:after {
            background: $brand-manager;
        }

        &:not(.collapsed) {
            background-color: $brand-manager-pale;
        }
    }
}

.prospector {
    .hero {
        background-image: url("/images/2024-09/hero-back-finder.jpg");
    }

    .focus-bg {
        @extend .bg-brand-finder-pale;
    }

    .accordion-button {
        &:after {
            background: $brand-finder;
        }

        &:not(.collapsed) {
            background-color: $brand-finder-pale;
        }
    }
}

.data-api {
    .hero {
        background-image: url("/images/2024-09/hero-back-api.jpg");
        color: white;
    }

    .accordion-button {
        &:after {
            background: $brand-api;
        }

        &:not(.collapsed) {
            background-color: $brand-api-pale;
        }
    }
}




.alert ul {
    padding: 0;
}

.alert li {
    list-style-type: none;
}

#spinner-div {
    min-height: 30px;
}

.alert a {
    text-decoration: underline;
}

#buttons .btn {
    margin: 0 0.5em;
}

.success {
    color: #3C763D;
}

.table > tbody > tr.main-row > td, .table > tbody > tr.main-row > th {
    border-top: 2px solid #888;
}

.table > thead > tr > th {
    border-bottom: 2px solid #888;
}

.home-banner {
    text-align: center;

    h1 {
        font-weight: 700;
    }

    .no-header-image h1 {
        margin: 2em;
    }
}


/* Blog */
h1.master {
    font-weight: 800;
}
hr.article-list-sep {
    border-top: 4px solid #1a1a1a;
}
article header {
    text-align: left;
    color: #1a1a1a;
}
article header h1 {
    font-weight: 800;
}
article {
    margin-bottom: 7em;
}
aside > .blog-sidebar {
    > div {
        border-top: 4px solid $brand-red;
        margin-top: 40px;
    }
    .list-group > .list-group-item {
        border: none;
    }
}
aside h4 {
    padding: 0.4em 0;
}
.post-navigation {
    display: flex;
    gap: 1em;
    margin-bottom: 2em;
}
.post-navigation a {
    color: #1a1a1a;
    padding: 1.75em 0;
    text-decoration: none;
    @media (min-width: 768px) {
        max-width: 300px;
    }
}
.post-navigation a:hover .post-title {
    color: #007acc;
}
.post-navigation div + div {
    border-top: 4px solid #1a1a1a;
}
.post-navigation span {
    display: block;
}
.post-navigation .meta-nav {
    color: #686868;
    font-size: 0.8rem;
    line-height: 1.6em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}
.post-navigation .post-title {
    font-size: 1.75rem;
    line-height: 1.25em;
    font-weight: 700;
}
/* Blog end */

@media (max-width: 768px) {
    .home-banner .intro-text .btn {
        font-size: 0.8em;
        line-height: 0.7em;
        padding: 15px 20px;
    }
}
.home-banner .pricing-header {
    margin-top: 200px;
}
.home-banner .intro-text .intro-lead-in, .home-banner .pricing-header {
    font-weight: 700;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 25px;
}
.home-banner .intro-text .intro-heading,
.home-banner .intro-text .intro-sub {
    font-style: italic;
    font-size: 22px;
    font-weight: normal;
    line-height: 22px;
    margin-top: 25px;
    margin-bottom: 25px;
    display: block;
}
@media (min-width: 768px) {
    .home-banner .intro-text {
        padding-top: 150px;
        padding-bottom: 100px;
    }
    .home-banner .intro-text .intro-lead-in {
        font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 700;
        font-size: 75px;
        line-height: 75px;
        margin-bottom: 25px;
    }
    .home-banner .intro-text .intro-heading,
    .home-banner .intro-text .intro-sub {
        font-family: "Droid Serif", "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-style: italic;
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 50px;
    }
}

.manager main > div:nth-child(n + 2 of .feature-row-toggle) {
    &:before {
        background-image: url("/images/2024-09/blue-arrow.png");
    }
}

.prospector main > div:nth-child(n + 2 of .feature-row-toggle),
.page-flow-arrow-left {
    &:before {
        background-image: url("/images/2024-09/yellow-arrow.png");
    }
}

.data-api main > div:nth-child(n + 2 of .feature-row-toggle) {
    &:before {
        background-image: url("/images/2024-09/purple-arrow.png");
    }
}

.extension main > div:nth-child(n + 2 of .feature-row-toggle) {
    &:before {
        background-image: url("/images/2024-09/red-arrow.png");
    }
}

main > div:nth-child(2n + 1 of .feature-row-toggle) {
    &:before {
        transform: scaleX(-1);
    }
}

.row.narrow {
    padding-left: 5%;
    padding-right: 5%;
}

.prospector .popular {
    background: $brand-finder;
}

/* FAQs */
.topic {
    margin-top: 2em;
    margin-bottom: 1em;
}

.topic p::first-letter {
    font-size: 1.5em;
}

.topic .topic-title {
    font-weight: bold;
}

/* Mailing Forms */
.mailing-form  {
    background-color: $pale-blue;
    .list-group > .list-group-item {
        background-color: transparent;
        border: none;
    }
    .form-label > span {
        color: $brand-red;
        padding-right: 0.7em;
        vertical-align: middle;
    }
}
