// The master version of this file should be in patma_site/theme/static_src/scss
//
// Footer
body footer {
    background-color: black;
    color: white;

    > .container {
        padding-top: 50px;
        background-image: url('/images/2024-09/footer-colours.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: bottom;
    }

    a {
        color: white;
    }
}
// End footer

header {
    box-shadow: 0 7px 9px -7px rgba(0,0,0,0.4);
}

.navbar-nav .nav-item {
    position: relative;

    &:after {
        display: none;
        content: '';
        position: absolute;
        bottom: 0.3em;
        background: $brand-red;
        width: 100%;
        height: 0.2em;
        border-radius: 1em;
        transform: skewY(1deg);
    }

    &.active {
        &:after {
            display: block;
        }
    }

    &:hover {
        &:after {
            display: block;
            background: $primary;
        }
    }
}

.edge-blur, .edge-blur-no-top {
    position: relative;
    display: inline-block;
    color: white;
    z-index: -1;

    &:after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-collapse: separate;
        box-shadow: inset 0 0 80px 28px,
            inset 0 0 40px 16px,
            inset 0 0 20px 10px;
    }
}

.edge-blur-no-top {
    &:after {
        box-shadow: inset 0 -1em 80px 28px,
            inset 0 -1em 40px 16px,
            inset 0 -1em 20px 10px;
    }
}

.hero {
    background-size: 100%;
    @include media-breakpoint-down(sm) {
        background-size: auto 100%;
    }
}

.accordion-button {
    &:after {
        mask-image: $accordion-button-icon;
        background: $brand-red;
    }

    &:not(.collapsed) {
        background-color: $brand-red-pale;

        &:after {
            mask-image: $accordion-button-active-icon;
        }
    }
}

.clip-bg-bottom-left-blue {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom left;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M 30 101 L 0 101 L 0 90 Z' stroke='%23DCEDFF' stroke-width='1' fill='%23DCEDFF'/%3E%3C/svg%3E%0A");

    .hero {
        background-image: none !important;
        color: inherit !important;
    }
}

.clip-bg-blue {
    background-color: $pale-blue;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top right;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M 70 -1 L 100 -1 L 100 10 Z' stroke='%23ffffff' stroke-width='1' fill='%23ffffff'/%3E%3C/svg%3E%0A");
}

.clip-bg-top-right-blue {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top right;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'%3E%3Cpath d='M 70 -1 L 100 -1 L 100 10 Z' stroke='%23DCEDFF' stroke-width='1' fill='%23DCEDFF'/%3E%3C/svg%3E%0A");
}

.btn-cta {
    @extend .bg-brand-red;
    @extend .text-white;
    @extend .rounded-pill;
    @extend .px-4;
}

.screenshot-wrapper {
    border-radius: 3em 3em 0 3em;
    box-shadow: 2em 2em 1em -1.3em;
}

.feature-row {
    padding-bottom: 3em;
    padding-top: 3em;

    h3 {
        padding-bottom: 2em;
    }

    .screen-holder {
        @include media-breakpoint-down(sm) {
            margin-top: 2em;
        }
        @include media-breakpoint-up(md) {
            @include make-col(6);
            // Borrowed from make-col-offset()
            $num: calc(1 / 12);
            margin-left: if($num == 0, 0, percentage($num));
        }
    }
}

main > div:nth-child(n + 2 of .feature-row-toggle),
.page-flow-arrow-left {
    @include media-breakpoint-down(md) {
        margin-top: 1.5em;
    }
    &:before {
        content: "";
        display: block;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("/images/2024-09/red-arrow.png");
        position: absolute;
        left: calc(50% - 3em);
        margin-top: -2.5em;
        height: 6em;
        width: 6em;
        margin-left: auto;
        margin-right: auto;
        @include media-breakpoint-down(md) {
            margin-top: -3.8em;
        }
    }
}

.page-flow-arrow-left {
    margin-top: 1.5em;

    &:before {
        left: 15%;
        z-index: -1;
    }
}

main > div:nth-child(2n + 1 of .feature-row-toggle) {
    &:before {
        transform: scaleX(-1);
    }
}

main > div:nth-child(odd of .feature-row-toggle) .feature-row {
    flex-direction: row-reverse;

    .screen-holder {
        @include media-breakpoint-up(md) {
            @include make-col(6);
            // Borrowed from make-col-offset()
            $num: calc(1 / 12);
            margin-right: if($num == 0, 0, percentage($num));
            margin-left: 0;
        }

        .screenshot-wrapper {
            border-radius: 3em 3em 3em 0;
            box-shadow: -2em 2em 1em -1.3em;
        }
    }
}

/* Pricing switch 2024-09 */
.switch-wrapper {
    position: relative;
    display: inline-flex;
    padding: 0.5em;
    margin-bottom: 1em;
    border-radius: 30px;
    background: $brand-finder-pale;
    font-weight: bold;

    [type="radio"] {
        position: absolute;
        left: -9999px;

        &:checked#monthly ~ label[for="monthly"]:hover,
        &:checked#yearly ~ label[for="yearly"]:hover {
            background: transparent;
        }

        &:checked#monthly + label[for="yearly"] ~ .highlighter {
            transform: none;
        }

        &:checked#yearly + label[for="monthly"] ~ .highlighter {
            transform: translateX(100%);
        }
    }

    label {
        z-index: 1;
        min-width: 100px;
        line-height: 2em;
        cursor: pointer;
        border-radius: 30px;
        transition: color 0.25s ease-in-out;
        margin-bottom: 0;

        &:hover {
            background: lightgray;
        }
    }

    .highlighter {
        position: absolute;
        top: 0;
        left: 0;
        width: 50%;
        height: 100%;
        border-radius: 30px;
        background: $brand-finder;
        transition: transform 0.25s ease-in-out;
    }
}
// Switch end

/***
Pricing table 2024-09
***/
.pricing {
    position: relative;
    border-radius: 0;
    border: 4px solid;

    ul {
        border-bottom: none;
        li {
            display: block;
        }
    }

    padding-bottom: 2em;
    > a.btn {
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 1.2em);
        text-wrap: nowrap;
    }

    > div.popular {
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, -1.2em);
        text-wrap: nowrap;
        padding: 0.2em 2em;
        font-size: large;
        font-style: italic;
    }
}

/*Pricing Content*/
.pricing-features {
    .list-group-item {
        padding: 0;

        .row > div {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    li:first-of-type {
        position: sticky;
        top: 4.2em;
        z-index: 10;
    }
}
